<template>
  <div ref="full-video-header" class="full-video-header">
    <div class="hoverOpa wallpaper-controller">
      <div class="wallpaper-controls">
        <div class="wallpaper-love-icon" :class="{ active: wallpaperFavoriteList.includes(currentWallpaper) }" ks-text="收藏到最爱壁纸" ks-tag="left">
          <i class="fa fa-heart" aria-hidden="true" @click.stop="addFavoriteWallpaper()"></i>
        </div>
        <div class="wallpaper-love-icon" ks-text="上一张" ks-tag="left">
          <i class="fa fa-arrow-left" aria-hidden="true" @click.stop="changeWallpaper(-1)"></i>
        </div>
        <div class="wallpaper-love-icon" ks-text="随机刷新壁纸" ks-tag="left">
          <i class="fa fa-refresh" aria-hidden="true" @click.stop="changeWallpaper(0)"></i>
        </div>
        <div class="wallpaper-love-icon" ks-text="下一张" ks-tag="left">
          <i class="fa fa-arrow-right" aria-hidden="true" @click.stop="changeWallpaper(1)"></i>
        </div>
        <label ks-text="启用动态壁纸" ks-tag="left" class="switch">
          <input type="checkbox" v-model="showPosterVideo">
          <span class="slider"></span>
        </label>
      </div>
      <div class="wallpaper-info">
        <div class="wallpaper-love-icon theme-name">{{ currentWallpaperTheme || "我的最爱" }}</div>
        <div class="wallpaper-navigation">
          <div class="wallpaper-love-icon" ks-text="全屏" ks-tag="left">
            <i class="fa" :class="{'fa-compress': isFullScreen, 'fa-expand': !isFullScreen}" aria-hidden="true" @click.stop="toggleFullscreen()"></i>
          </div>
          <div class="wallpaper-love-icon" ks-text="上一页" ks-tag="left">
            <i class="fa fa-arrow-left" aria-hidden="true" @click.stop="scrollTo('left')"></i>
          </div>
          <div class="wallpaper-love-icon" ks-text="下一页" ks-tag="left">
            <i class="fa fa-arrow-right" aria-hidden="true" @click.stop="scrollTo('right')"></i>
          </div>
        </div>
      </div>
      <div ref="wallSwitch-tips" class="wallSwitch-tips overflow-x-auto no-scrollbar">
          <div class="wallSwitch-tips-item" :class="{active:currentWallpaperTheme==item.name}"
          :ks-text="item.name" ks-tag="right" 
            :key="item" v-for="item in wallpaperFolder"
            @click.stop="changeWallpaperTheme(item.name)"
            >
            <svg data-v-7e48b1e8="" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" 
            ><path data-v-7e48b1e8="" d="M777.514667 131.669333a53.333333 53.333333 0 0 1 0 75.434667L728.746667 255.829333h49.92A160 160 0 0 1 938.666667 415.872v320a160 160 0 0 1-160 160H245.333333A160 160 0 0 1 85.333333 735.872v-320a160 160 0 0 1 160-160h49.749334L246.4 207.146667a53.333333 53.333333 0 1 1 75.392-75.434667l113.152 113.152c3.370667 3.370667 6.186667 7.04 8.448 10.965333h137.088c2.261333-3.925333 5.12-7.68 8.490667-11.008l113.109333-113.152a53.333333 53.333333 0 0 1 75.434667 0z m1.152 231.253334H245.333333a53.333333 53.333333 0 0 0-53.205333 49.365333l-0.128 4.010667v320c0 28.117333 21.76 51.157333 49.365333 53.162666l3.968 0.170667h533.333334a53.333333 53.333333 0 0 0 53.205333-49.365333l0.128-3.968v-320c0-29.44-23.893333-53.333333-53.333333-53.333334z m-426.666667 106.666666c29.44 0 53.333333 23.893333 53.333333 53.333334v53.333333a53.333333 53.333333 0 1 1-106.666666 0v-53.333333c0-29.44 23.893333-53.333333 53.333333-53.333334z m320 0c29.44 0 53.333333 23.893333 53.333333 53.333334v53.333333a53.333333 53.333333 0 1 1-106.666666 0v-53.333333c0-29.44 23.893333-53.333333 53.333333-53.333334z" fill="#fff"></path></svg>
          </div>
      </div>
      <div ks-text="切换声音" ks-tag="bottom" >
        <div class="wallpaper-love-icon" style="
        width: 240px;line-height: 34px;
        font-size: 20px;
        position: relative;
        white-space: nowrap;overflow: hidden;text-overflow: ellipsis; "
        @click.stop="(isMuted=!isMuted)">
          <div class="text-marquee" 
          style="position: absolute;overflow:visible;right: 0;">
            <i class="fa" :class="{'fa-volume-up':!isMuted,'fa-volume-off':isMuted}" aria-hidden="true"></i>
            {{ currentWallpaper.match(/[^/]+$/)?.[0].split(".")?.[0] }}
          </div>
        </div>
      </div>
      <el-select v-model="selectedFont" placeholder="选择字体" 
        clearable  @change="handleFontChange"
        style="background-color: #F0F0F0 !important;">
        <el-option v-for="font in fonts" :key="font.name" :label="font.name" :value="font.name">
          <span :style="{ fontFamily: font.import }">{{ font.name }}</span>
        </el-option>
      </el-select>
    </div>
    <video ref="video" class="background-image index-full-video" autoplay muted loop 
    :src="showPosterVideo ? currentWallpaper : DEFAULT_WALLPAPER" 
    :poster="DEFAULT_WALLPAPER"></video>
  </div>
  </template>

  
<script>
import { getAlistContent } from '../../utils/api';
import { navigation, isMobile } from '../../utils/tools';

export default {
  props: {
    showPosterVideo: { type: Boolean, default: undefined },
  },
  data() {
    return {
      articles: [],
      wallpaperFolder: [],
      wallpaperList: [],
      currentWallpaper: '',
      wallpaperFavoriteList: [],
      currentWallpaperTheme: '',
      isMuted: true,
      isFullScreen: false,
      selectedFont: localStorage.getItem('fontFamilyName') || 'HarmonyOS Sans SC',
    };
  },
  computed: {
    fonts() {
      return this.$store.state.fonts;
    },
    DEFAULT_WALLPAPER() {
      if (localStorage.getItem('DEFAULT_WALLPAPER')) {
        const wallpaper = JSON.parse(localStorage.getItem('DEFAULT_WALLPAPER'));
        return isMobile() ? wallpaper.PE : wallpaper.PC;
      }
      return this.$store.state.webInfo.backgroundImage;
    },
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  watch: {
    isMuted(newValue, oldValue) {
      if (oldValue !== undefined) {
        localStorage.setItem('isMuted', this.isMuted);
      }
    },
    '$store.state.currentWallpaper'(newValue, oldValue) {
      if (oldValue !== undefined) {
        this.currentWallpaper = newValue;
      }
    },
    showPosterVideo(newValue, oldValue) {
      if (newValue) {
        document.body.classList.add('showPosterVideo', 'no-scrollbar');
      } else {
        document.body.classList.remove('showPosterVideo', 'no-scrollbar');
      }
      if (oldValue !== undefined) {
        this.global.showPosterVideo = this.showPosterVideo;
        localStorage.setItem('showPosterVideo', this.showPosterVideo);
        this.changeWallpaper(0);
      }
    },
    currentWallpaperTheme(newValue, oldValue) {
      if (oldValue !== undefined) {
        localStorage.setItem('currentWallpaperTheme', this.currentWallpaperTheme);
        this.changeWallpaperTheme(newValue);
      }
    },
  },
  mounted() {
    this.initializeWallpaper();
    window.addEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    initializeWallpaper() {
      this.wallpaperFolder = JSON.parse(localStorage.getItem('alistSource_wallpaperFolder'));
      this.wallpaperFavoriteList = JSON.parse(localStorage.getItem('favoriteWallpaper')) || [];
      this.wallpaperList = this.wallpaperFavoriteList;
      this.showPosterVideo = localStorage.getItem('showPosterVideo') === 'true';
      this.isMuted = localStorage.getItem('isMuted') === 'true';

      setTimeout(() => {
        this.$nextTick(() => {
          if (this.$store.state.currentWallpaper) {
            this.changeWallpaper(0);
            this.currentWallpaper = this.$store.state.currentWallpaper;
          } else {
            this.changeWallpaper(0);
          }
        });
      }, 1000);
    },
    handleFontChange() {
      const fontFamily = this.fonts.find(font => font.name === this.selectedFont);
      localStorage.setItem('fontFamilyName', fontFamily.name);
      const fontUrl = fontFamily?.href;
      const fontLink = document.createElement('link');
      fontLink.setAttribute('rel', 'stylesheet');
      fontLink.setAttribute('href', fontUrl);
      document.head.appendChild(fontLink);
      document.documentElement.style.setProperty('--globalFont', fontFamily?.import);
    },
    handleKeyDown(event) {
      if (!this.showPosterVideo) return;

      if ([37, 38, 39, 40].includes(event.keyCode)) event.preventDefault();

      switch (event.keyCode) {
        case 37:
          this.changeWallpaper(-1);
          break;
        case 39:
          this.changeWallpaper(1);
          break;
        case 38:
          navigation('body');
          break;
        case 40:
          navigation('.page-container-wrap');
          break;
      }
    },
    toggleFullscreen() {
      this.isFullScreen = !this.isFullScreen;
      if (this.isFullScreen) {
        this.enterFullscreen();
      } else {
        this.exitFullscreen();
      }
    },
    enterFullscreen() {
      const element = document.documentElement;
      if (element.requestFullscreen) element.requestFullscreen();
      else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
      else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
      else if (element.msRequestFullscreen) element.msRequestFullscreen();
    },
    exitFullscreen() {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
    },
    scrollTo(direction) {
      const container = this.$refs['wallSwitch-tips'];
      const containerWidth = container.offsetWidth;
      const contentWidth = container.scrollWidth;
      const scrollLeft = container.scrollLeft;
      let targetScrollLeft = direction === 'left' ? scrollLeft - containerWidth : scrollLeft + containerWidth;

      if (targetScrollLeft <= -containerWidth) targetScrollLeft = contentWidth - containerWidth;
      if (targetScrollLeft >= contentWidth) targetScrollLeft = 0;
      if (targetScrollLeft < 0) targetScrollLeft = 0;
      if (targetScrollLeft >= contentWidth - containerWidth) targetScrollLeft = contentWidth - containerWidth;

      container.scrollTo({
        left: targetScrollLeft,
        behavior: 'smooth',
      });
    },
    changeWallpaper(index) {
      if (index === 0 && this.wallpaperList.length > 0) {
        this.$store.commit('changeCurrentWallpaper', this.wallpaperList[Math.floor(Math.random() * this.wallpaperList.length)]);
      } else {
        const currentIndex = this.wallpaperList.indexOf(this.currentWallpaper);
        const newIndex = currentIndex + index;
        if (newIndex >= 0 && newIndex < this.wallpaperList.length) {
          this.$store.commit('changeCurrentWallpaper', this.wallpaperList[newIndex]);
        }
      }
    },
    changeWallpaperTheme(Folder) {
      this.currentWallpaperTheme = Folder;
      getAlistContent({ path: `/wallpaper/${Folder}`, password: '', page: 1, per_page: 100, refresh: false })
        .then(res => {
          const urlTemplate = `//alist.roginx.ink/d/wallpaper/${Folder}/\${name}`;
          this.wallpaperList = res.data.content.map(file => {
            const { name } = file;
            const url = urlTemplate.replace('${name}', name);
            return url;
          });
          this.$nextTick(() => {
            this.changeWallpaper(0);
          });
        })
        .catch(console.log);
    },
    addFavoriteWallpaper() {
      let favoriteWallpaperList = JSON.parse(localStorage.getItem('favoriteWallpaper')) || [];
      if (favoriteWallpaperList.includes(this.currentWallpaper)) {
        favoriteWallpaperList = favoriteWallpaperList.filter(wallpaper => wallpaper !== this.currentWallpaper);
      } else {
        favoriteWallpaperList.push(this.currentWallpaper);
      }
      this.wallpaperFavoriteList = favoriteWallpaperList;
      localStorage.setItem('favoriteWallpaper', JSON.stringify(favoriteWallpaperList));
    },
  },
};
</script>
<style scoped>
.index-full-video {
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
  background-color: #000;
}
.full-video-header {
  user-select: none;
}
.wallpaper-controller {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 30px;
  top: 70px;
  z-index: 99;
  text-shadow: 2px 2px 3px #ff000088;
}
.wallpaper-controls,
.wallpaper-info,
.wallpaper-navigation {
  display: flex;
  justify-content: space-between;
}
.wallpaper-love-icon {
  cursor: pointer;
  display: inline-block;
  height: 34px;
  margin: 0 10px;
  vertical-align: super;
  line-height: 34px;
  font-size: inherit;
  color: #fff;
  transition: 0.6s;
}
.wallpaper-love-icon:hover i,
.wallpaper-love-icon.active i {
  transition: 0.6s;
  color: rgb(242, 93, 100);
}
.wallSwitch-tips {
  height: 48px;
  width: 240px;
  display: flex;
  align-items: center;
  font-size: 22px;
}
.wallSwitch-tips-item {
  margin: 10px;
  width: 20px;
  display: inline-flex;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
}
.wallSwitch-tips-item path {
  transition: 0.5s;
}
.wallSwitch-tips-item.active svg path,
.wallSwitch-tips-item:hover path {
  fill: var(--themeBackground) !important;
}
.sound-toggle {
  width: 240px;
  line-height: 34px;
  font-size: 20px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}
.font-select {
  background-color: #f0f0f0 !important;
}
</style>
